import React from 'react';

function ColorPreset({ presets, onSelectColor, selectedColor, toggleColorSelector, selectedCategory, paletteIcon }) {
  return (
    <div className="color-preset">
      {presets.map((color, index) => (
        <button
          key={index}
          className={`color-preset-button ${selectedColor === color ? 'selected' : ''}`}
          style={{ backgroundColor: color }}
          onClick={() => onSelectColor(color)}
        />
      ))}
      <button
        className="toggle-color-selector-button"
        onClick={toggleColorSelector}
        disabled={selectedCategory === 'eye_highlight'} // eyehighlight 선택 시 비활성화
      >
        <img src={paletteIcon} alt="Color Selector" />
      </button>
    </div>
  );
}

export default ColorPreset;
