import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LZString from 'lz-string';
import '../App.css';
import Canvas from './Canvas';
import confetti from 'canvas-confetti'; // confetti 라이브러리 임포트

const CompletePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const canvasRef = useRef(null);

  // State to hold decoded data
  const [decodedData, setDecodedData] = useState({ selectedParts: {}, colors: {} });

  useEffect(() => {
    
    const params = new URLSearchParams(location.search);
    const encodedData = params.get('cd');
       
    if (encodedData) {
      // Decompress and decode the base64 string to JSON
      const decoded = JSON.parse(LZString.decompressFromEncodedURIComponent(encodedData));
      setDecodedData(decoded);
    }
  }, [location]);

  useEffect(() => {
    // confetti 효과 실행
    const basic = () => {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
    };

    basic();
  }, []); // 빈 의존성 배열로 컴포넌트가 로드될 때 한 번 실행

  const handleDownload = () => {
    if (canvasRef.current) {
      const link = document.createElement('a');
      link.href = canvasRef.current.toDataURL('image/png');
      link.download = 'cookie_by_butterscone.png';
      link.click();
    }
  };

  return (
    <div className="App complete-page-container">
      <div className="canvas-container">
        <Canvas
          ref={canvasRef}
          selectedParts={decodedData.selectedParts}
          colors={decodedData.colors}
        />
      </div>
      <div className="complete-page-textarea">
        <h2>완성되었어요!</h2>
        <button className="toggle-panel-button" onClick={handleDownload}>save image</button>
        <button className="toggle-panel-button" onClick={() => navigate('/')}>replay</button>
      </div>
      <br>
    </br>
      <div className="complete-page-textarea">
  <p>이 서비스는 Misskey 서버 <strong>버터스콘</strong>에서 제공됩니다.</p>
  <p>마스토돈 서버와 소통하며, 리액션과 글자 꾸미기 등 각종 즐거운 기능들을 사용해 보세요!</p>
  <div className="button-row">
    <button
      className="footer-button"
      onClick={() => window.open('https://buttersc.one/', '_blank')}
    >
      버터스콘 둘러보기
    </button>
    <button
      className="footer-button"
      onClick={() => window.open('https://misskey-hub.net/en/servers/', '_blank')}
    >
      Misskey 서버 <br></br> 둘러보기
    </button>
  </div>
</div>
<div className="license-info">
    <p>
      버터스콘 쿠키 메이커는 <strong>CC BY</strong> 라이선스를 사용합니다. 저작자 표시
      (<a href="https://buttersc.one" target="_blank" rel="noopener noreferrer">buttersc.one</a>)만 있다면 2차 수정, 영리적 사용, 재배포가 자유롭습니다.
    </p>
  </div>
    </div>
  );
};

export default CompletePage;
