// components/ColorSelector.js
import React, { useState, useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';

function ColorSelector({ selectedCategory, selectedColor, onSelectColor, onResetColor }) {
  const [localColor, setLocalColor] = useState(selectedColor);

  useEffect(() => {
    setLocalColor(selectedColor);
  }, [selectedColor]);

  const handleColorChange = (color) => {
    setLocalColor(color);
    onSelectColor(color);
  };

  return (
    <div className="color-selector">
      <HexColorPicker color={localColor} onChange={handleColorChange} />
      <div className="color-info">
        <input
          type="text"
          value={localColor}
          onChange={(e) => handleColorChange(e.target.value)}
        />
        <button className="reset-button" onClick={onResetColor}>reset</button>
      </div>
    </div>
  );
}

export default ColorSelector;