import React, { useState, useRef, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Canvas from './components/Canvas';
import PartSelector from './components/PartSelector';
import ColorSelector from './components/ColorSelector';
import ColorPreset from './components/ColorPreset';
import ControlPanel from './components/ControlPanel';
import CompletePage from './components/CompletePage';
import './App.css';
import randomButtonIcon from './images/randombutton.svg';
import paletteIcon from './images/palette.svg';
import moveIcon from './images/move.svg';
import LZString from 'lz-string';


function AppContent() {

  const categoryGroups = {
    bg: ['bg_bg'],
    face: ['face_face', 'face_ears', 'face_blush', 'face_mouth', 'face_eyebrow','face_deco'],
    eye: ['eye_iris', 'eye_eyewhite', 'eye_highlight', 'eye_eyelid', 'eye_deco','eye_deco2'],
    hair: ['hair_back', 'hair_backadd', 'hair_sideR', 'hair_sideL', 'hair_front', 'hair_add', 'hair_add2', 'hair_tieR', 'hair_tieL','hair_decof', 'hair_decof2','hair_decob',],
  };
// 상태 초기화 시 기본값을 첫 번째 카테고리로 설정

const partCounts = {
  bg_bg: 1,
  face_face: 3,
  face_ears: 6,
  face_blush: 5,
  face_mouth: 18,
  face_deco: 6,
  face_eyebrow: 11,
  eye_eyewhite: 2,
  eye_iris: 4,
  eye_highlight: 1,
  eye_eyelid: 10,
  eye_deco: 7,
  eye_deco2: 7,
  hair_decof: 15,
  hair_decof2: 15,
  hair_decob: 5,
  hair_add: 10,  
  hair_add2: 10,  
  hair_front: 27, 
  hair_sideL: 20, 
  hair_sideR: 20, 
  hair_tieL: 11, 
  hair_tieR: 11, 
  hair_back: 14,
  hair_backadd: 8,  

};

const initialParts = {
  bg_bg:  { src: '/assets/bg_bg/empty.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  face_face: { src: '/assets/face_face/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  face_ears: { src: '/assets/face_ears/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  face_blush: { src: '/assets/face_blush/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  face_mouth: { src: '/assets/face_mouth/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  face_eyebrow: { src: '/assets/face_eyebrow/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  face_deco: { src: '/assets/face_deco/empty.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  eye_iris: { src: '/assets/eye_iris/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  eye_eyewhite: { src: '/assets/eye_eyewhite/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  eye_highlight: { src: '/assets/eye_highlight/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  eye_eyelid: { src: '/assets/eye_eyelid/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  eye_deco: { src: '/assets/eye_deco/empty.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  eye_deco2: { src: '/assets/eye_deco2/empty.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  hair_back: { src: '/assets/hair_back/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  hair_backadd: { src: '/assets/hair_backadd/empty.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  hair_sideR: { src: '/assets/hair_sideR/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  hair_sideL: { src: '/assets/hair_sideL/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  hair_tieR: { src: '/assets/hair_sideR/_empty.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  hair_tieL: { src: '/assets/hair_sideL/_empty.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  hair_front: { src: '/assets/hair_front/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  hair_add: { src: '/assets/hair_add/_00001.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  hair_add2: { src: '/assets/hair_add/empty.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  hair_decof: { src: '/assets/hair_decof/empty.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  hair_decof2: { src: '/assets/hair_decof2/empty.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
  hair_decob: { src: '/assets/hair_decob/empty.png', position: { x: 0, y: 0 }, rotation: 0, flipped: false },
};

  

const initialColors = {
  bg_bg: '#84a5db',
  face_face: '#fdf6e9',
  face_ears: '#fdf6e9',
  face_blush: '#fdf6e9',
  face_mouth: '#d4726a',
  face_eyebrow: '#84a5db',
  face_deco: '#fdf6e9',
  eye_eyewhite: '#ffffff',
  eye_iris: '#84a5db',
  eye_highlight: '#ffffff',
  eye_eyelid: '#fdf6e9',
  eye_deco: '#ffffff',
  eye_deco2: '#ffffff',
  hair_back: '#84a5db',
  hair_backadd: '#84a5db',
  hair_sideR: '#84a5db',
  hair_sideL: '#84a5db',
  hair_tieR: '#84a5db',
  hair_tieL: '#84a5db',
  hair_front: '#84a5db',
  hair_add: '#84a5db',
  hair_add2: '#84a5db',
  hair_decof: '#ffffff',
  hair_decof2: '#ffffff',
  hair_decob: '#ffffff',
};


const [selectedParts, setSelectedParts] = useState(initialParts);
const [selectedGroup, setSelectedGroup] = useState('face'); // 초기 그룹
  const [selectedCategory, setSelectedCategory] = useState(
    categoryGroups['face'][0] // 초기 카테고리
  );const [isControlPanelOpen, setIsControlPanelOpen] = useState(false);
const [isColorSelectorOpen, setIsColorSelectorOpen] = useState(false);
const [colors, setColors] = useState(initialColors);

  const MemoizedCanvas = useMemo(() => React.memo(Canvas), []);

  const canvasRef = useRef(null);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const toggleColorSelector = () => {
    setIsColorSelectorOpen(!isColorSelectorOpen);
    setIsControlPanelOpen(false);
    
  };

  const toggleControlPanel = () => {
    // 조건을 제거하여 모든 파츠에서 ControlPanel을 열고 닫을 수 있음
    setIsControlPanelOpen(!isControlPanelOpen);
    setIsColorSelectorOpen(false);
    
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // ControlPanel 바깥 클릭 감지
      if (isControlPanelOpen && !event.target.closest('.control-panel')) {

        console.log('Clicked outside ControlPanel');
        setIsControlPanelOpen(false);
      }
  
      // ColorSelector 바깥 클릭 감지
      if (isColorSelectorOpen && !event.target.closest('.color-selector')) {

        console.log('Clicked outside ColorSelector');
        setIsColorSelectorOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isControlPanelOpen, isColorSelectorOpen]);
  
  const movePart = (direction) => {
    // 조건 제거, 모든 파츠에서 이동 가능
    setSelectedParts((prevParts) => {
      const newPosition = { ...prevParts[selectedCategory].position };
      switch (direction) {
        case 'up':
          newPosition.y -= 5;
          break;
        case 'down':
          newPosition.y += 5;
          break;
        case 'left':
          newPosition.x -= 5;
          break;
        case 'right':
          newPosition.x += 5;
          break;
        default:
          break;
      }
      return {
        ...prevParts,
        [selectedCategory]: {
          ...prevParts[selectedCategory],
          position: newPosition,
        },
      };
    });
  };
  
  const flipPart = () => {
    // 조건 제거, 모든 파츠에서 뒤집기 가능
    setSelectedParts((prevParts) => ({
      ...prevParts,
      [selectedCategory]: {
        ...prevParts[selectedCategory],
        flipped: !prevParts[selectedCategory].flipped,
      },
    }));
  };
  
  const rotatePart = (angle) => {
    // 조건 제거, 모든 파츠에서 회전 가능
    setSelectedParts((prevParts) => ({
      ...prevParts,
      [selectedCategory]: {
        ...prevParts[selectedCategory],
        rotation: (prevParts[selectedCategory].rotation + angle) % 360,
      },
    }));
  };

  const handleColorChange = (color) => {
    setColors(prevColors => {
      const newColors = { ...prevColors };
      if (['face_face', 'face_blush', 'face_eyelid'].includes(selectedCategory)) {
        newColors.face_face = color;
        newColors.face_blush = color;
        newColors.face_eyelid = color;
      } else {
        newColors[selectedCategory] = color;
      }
      return newColors;
    });
  };

  const handleResetColor = () => {
    setColors(prevColors => {
      const newColors = { ...prevColors };
      if (['face_face', 'face_blush', 'face_eyelid'].includes(selectedCategory)) {
        newColors.face_face = initialColors.face_face;
        newColors.face_blush = initialColors.face_face;
        newColors.face_eyelid = initialColors.face_face;
      } else {
        newColors[selectedCategory] = initialColors[selectedCategory];
      }
      return newColors;
    });
  };

  const handleGroupChange = (group) => {
    setSelectedGroup(group); // 그룹 변경
    setSelectedCategory(categoryGroups[group][0]); // 첫 번째 카테고리로 이동
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category); // 카테고리 변경
    selectedCategory(category);
    setIsControlPanelOpen(false);
    setIsColorSelectorOpen(false);
  };

  const handlePartChange = (category, partId) => {
    setSelectedParts((prevParts) => ({
      ...prevParts,
      [category]: {
        ...prevParts[category],
        src: partId,
      },
    }));
  };
  const handleSaveImage = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      // Combine selected parts and colors into one object
      const data = {
        selectedParts,
        colors
      };
      
      // Convert to JSON and then to base64
      const compressedData = LZString.compressToEncodedURIComponent(JSON.stringify(data));
      
      // Navigate to CompletePage with the encoded data
      navigate(`/complete?cd=${compressedData}`);
    }
  };
  

  const colorPresets = {
    bg_bg: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'],
    face_face: ['#fdf6e9', '#fff8f3', '#c3a997', '#eac79e', '#9b8776', '#B4CAF6', '#BABABA', '#707070'],
    face_ears: ['#fdf6e9', '#fff8f3', '#c3a997', '#eac79e', '#9b8776', '#B4CAF6', '#BABABA', '#707070'],
    face_blush: ['#fdf6e9', '#fff8f3', '#c3a997', '#eac79e', '#9b8776', '#B4CAF6', '#BABABA', '#707070'],
    face_mouth: ['#d4726a', '#a6504f', '#8b4041'],
    face_deco: ['#ffffff','#fdf6e9', '#fff8f3', '#c3a997', '#eac79e', '#9b8776', '#B4CAF6', '#BABABA', '#707070','#6c706f'],
    face_eyebrow: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'],
    eye_eyewhite: ['#ffffff'],
    eye_iris: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'],
    eye_highlight: ['#ffffff'],
    eye_eyelid: ['#fdf6e9', '#fff8f3', '#c3a997', '#eac79e', '#9b8776', '#B4CAF6', '#BABABA', '#707070'],
    eye_deco: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'],
    eye_deco2: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'],
    hair_back: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'],
    hair_backadd: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'],
    hair_sideR: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'], // 새 카테고리
    hair_sideL: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'], // 새 카테고리
    hair_tieR: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'], // 새 카테고리
    hair_tieL: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'], // 새 카테고리
    hair_front: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'],
    hair_add: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'],    // 새 카테고리
    hair_add2: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'],    // 새 카테고리
    hair_decof: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'],  // 새 카테고리
    hair_decof2: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'],  // 새 카테고리
    hair_decob: ['#eea594', '#ae7b70', '#FFDC6F', '#f6f7b2', '#acd28e', '#6aaf7c', '#78c7c1', '#84a5db', '#f1c9dc', '#cd9ede', '#e4d9f1', '#faecec', '#dff5f1', '#ffffff', '#6c706f'],  // 새 카테고리
  };
  

  const handlePresetColorChange = (color) => {
    handleColorChange(color);
  };
  const handleDragScroll = (e) => {
    const container = e.currentTarget;
    let isDown = false;
    let startX;
    let scrollLeft;
  
    container.addEventListener('mousedown', (e) => {
      isDown = true;
      container.classList.add('active');
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
    });
  
    container.addEventListener('mouseleave', () => {
      isDown = false;
      container.classList.remove('active');
    });
  
    container.addEventListener('mouseup', () => {
      isDown = false;
      container.classList.remove('active');
    });
  
    container.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX) * 2; // 스크롤 속도 조절
      container.scrollLeft = scrollLeft - walk;
    });
  };
  
  const randomizeParts = () => {
    setSelectedParts((prevParts) => {
      const newParts = { ...prevParts };
  
      const randomFaceColorIndex = Math.floor(Math.random() * colorPresets.face_face.length);
      const randomFaceColor = colorPresets.face_face[randomFaceColorIndex];
  
      const randomHairColorIndex = Math.floor(Math.random() * colorPresets.hair_back.length);
      const randomHairColor = colorPresets.hair_back[randomHairColorIndex];
  
      Object.keys(newParts).forEach((part) => {
        const randomIndex = Math.floor(Math.random() * partCounts[part]);
        const paddedIndex = String(randomIndex + 1).padStart(5, '0');

      console.log(`Processing part: ${part}, Random Index: ${randomIndex}, Padded Index: ${paddedIndex}`); // 디버깅 추가
        newParts[part] = {
          ...newParts[part],
          src: `/assets/${part}/_${paddedIndex}.png`,
        };
  
        // 랜덤 색상 설정
        setColors((prevColors) => {
          const newColors = { ...prevColors };
  
          // face 그룹 동일 색상 적용
          if (['face_face', 'face_blush', 'face_ears', 'eye_eyelid'].includes(part)) {
            newColors[part] = randomFaceColor;
          }
          // hair 그룹 동일 색상 적용
          else if (['hair_back', 'hair_backadd', 'hair_tieL', 'hair_tieR', 'hair_sideR', 'hair_sideL', 'hair_front', 'face_eyebrow'].includes(part)) {
            newColors[part] = randomHairColor;
          
          }
          // 나머지 파츠는 개별 랜덤 색상 적용
          else {
            const randomColorIndex = Math.floor(Math.random() * colorPresets[part].length);
            newColors[part] = colorPresets[part][randomColorIndex];
          }
  
          return newColors;
        });
      });
      console.log('New parts:', newParts); // 최종 상태 디버깅

      return newParts;
    });
  };
  
  

  return (
    <div className="App">
      <div className="canvas-container">
        <MemoizedCanvas ref={canvasRef} selectedParts={selectedParts} colors={colors} />
        <button
          className="save-image-button"
          onClick={handleSaveImage}
        >
✔        </button>

<div className="random-button" onClick={randomizeParts}>
      <img src={randomButtonIcon} alt="Random" className="random-button-icon" />
    </div>
        <div className="overlay-buttons">
          
          <button
            className="toggle-panel-button"
            onClick={toggleColorSelector}
          >
            {isColorSelectorOpen ? 'X' : <img src={paletteIcon} alt="Color Selector" />}
          
          </button>
          <button
            className="toggle-panel-button"
            onClick={toggleControlPanel}
            disabled={['face', 'hairback'].includes(selectedCategory)}
          >
            {isControlPanelOpen ? 'X' : <img src={moveIcon} alt="Move" />}
          </button>
        </div>
        {isControlPanelOpen && (
          <div className="overlay-panel">
            <ControlPanel
              selectedCategory={selectedCategory} // 선택된 파츠 전달
              onMove={movePart}
              onFlip={flipPart}
              onRotate={rotatePart}
            />
          </div>
        )}
        {isColorSelectorOpen && (
          <div className="overlay-panel">
            <ColorSelector 
              selectedCategory={selectedCategory}
              selectedColor={colors[selectedCategory]}
              onSelectColor={handleColorChange}
              onResetColor={handleResetColor}
            />
          </div>
        )}
      </div>

      <div className="scrollable-container">
      <div className="category-groups">
  {Object.keys(categoryGroups).map((group) => (
    <button
      key={group}
      className={`group-button ${selectedGroup === group ? 'selected' : ''}`}
      onClick={() => {
        setSelectedGroup(group);
        setSelectedCategory(categoryGroups[group][0]); // 해당 그룹의 첫 번째 카테고리 선택
      }}
    >
      {group.toUpperCase()}
    </button>
  ))}
</div>

<div className="categories">
  <div
    className="scrollable-categories"
    onMouseDown={handleDragScroll}
  >
    {categoryGroups[selectedGroup]?.map((category) => (
      <button
        key={category}
        className={`category-button ${selectedCategory === category ? 'selected' : ''}`}
        onClick={() => setSelectedCategory(category)}
      >
        {category.replace(/^.*?_/, '').replace(/_/g, ' ')} {/* 접두사 제거 및 공백으로 변환 */}
      </button>
    ))}
  </div>




        </div>
        <ColorPreset
          presets={colorPresets[selectedCategory]}
          onSelectColor={handlePresetColorChange}
          selectedColor={colors[selectedCategory]} // 선택된 색상을 전달
          toggleColorSelector={toggleColorSelector}
          selectedCategory={selectedCategory}
          paletteIcon={paletteIcon}
        />
      </div>

      <div className="scrollable-container">
      <PartSelector
    selectedCategory={selectedCategory}
    selectedPart={selectedParts[selectedCategory]?.src}
    onSelectPart={handlePartChange}
    partCounts={Object.fromEntries(
      categoryGroups[selectedGroup].map((category) => [category, partCounts[category]])
    )} // 현재 선택된 그룹의 카테고리만 PartSelector에 전달
  />
</div>
<div className="license-info">
    <p>
      버터스콘 쿠키 메이커는 <strong>CC BY</strong> 라이선스를 사용합니다. 저작자 표시
      (<a href="https://buttersc.one" target="_blank" rel="noopener noreferrer">buttersc.one</a>)만 있다면 2차 수정, 영리적 사용, 재배포가 자유롭습니다.
    </p>
  </div>
    </div>
  );
}

function App() {
  return (
    
    <Router>
      <Routes>
        <Route path="/" element={<AppContent />} />
        <Route path="/complete" element={<CompletePage />} />
      </Routes>
    </Router>
  );
}

export default App;
