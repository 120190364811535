import React, { useEffect, useRef, forwardRef, useMemo } from 'react';
import { adjustColors } from '../utils/colorUtils';

const Canvas = forwardRef(({ selectedParts, colors }, ref) => {
  const canvasRef = useRef(null);
  const offscreenCanvasesRef = useRef({});

  // 메모이제이션을 사용하여 파츠와 색상이 변경될 때만 다시 계산
  const memoizedParts = useMemo(() => {
    return Object.entries(selectedParts).map(([partName, part]) => ({
      ...part,
      color: colors[partName],
      key: `${part.src}-${colors[partName]}-${part.position.x}-${part.position.y}-${part.rotation}-${part.flipped}`
    }));
  }, [selectedParts, colors]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const loadAndCacheImage = (part) => {
      return new Promise((resolve) => {
        const cacheKey = `${part.src}-${part.color}`;
        if (offscreenCanvasesRef.current[cacheKey]) {
          resolve(offscreenCanvasesRef.current[cacheKey]);
        } else {
          const img = new Image();
          img.src = part.src;
          img.onload = () => {
            const offscreenCanvas = document.createElement('canvas');
            offscreenCanvas.width = img.width;
            offscreenCanvas.height = img.height;
            const offscreenCtx = offscreenCanvas.getContext('2d');
          
            offscreenCtx.drawImage(img, 0, 0);
          
            const imageData = offscreenCtx.getImageData(0, 0, img.width, img.height);
            adjustColors(imageData, offscreenCtx, '#fdf6e9', part.color);
            offscreenCtx.putImageData(imageData, 0, 0);
          
            offscreenCanvasesRef.current[cacheKey] = offscreenCanvas;
            resolve(offscreenCanvas);
          };
          
          img.onerror = (err) => {
            console.error(`Failed to load image for src: ${part.src}`, err);
          };
        }
      });
    };

    const drawPart = (part, offscreenCanvas) => {
      const scaleX = canvas.width / offscreenCanvas.width; // 캔버스 너비에 맞춤
      const scaleY = canvas.height / offscreenCanvas.height; // 캔버스 높이에 맞춤
      const scale = Math.min(scaleX, scaleY); // 비율 유지
    
      ctx.save();
      ctx.translate(part.position.x + canvas.width / 2, part.position.y + canvas.height / 2);
      ctx.rotate((part.rotation * Math.PI) / 180);
      ctx.scale(part.flipped ? -scale : scale, scale); // 이미지 뒤집기 적용
      ctx.drawImage(offscreenCanvas, -offscreenCanvas.width / 2, -offscreenCanvas.height / 2);
      ctx.restore();
    };

    const drawParts = async () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Define the desired order of layers
const orderedLayers = [
  'bg_bg',
  'hair_decob',
  'hair_backadd',
  'hair_back',
  'hair_tieR',
  'hair_tieL',
  'face_ears',
  'face_face',
  'face_blush',
  'face_mouth',
  'face_deco',
  'eye_eyewhite',
  'eye_iris',
  'eye_highlight',
  'eye_eyelid',
  'eye_deco',
  'eye_deco2',
  'hair_sideR',
  'hair_sideL',
  'hair_front',
  'hair_add',
  'hair_add2',
  'hair_decof',
  'hair_decof2',
  'face_eyebrow'

];

    
      // Sort memoizedParts based on the layer order
      const orderedParts = orderedLayers
        .map(layer => memoizedParts.find(part => part.src.includes(layer)))
        .filter(Boolean); // Remove undefined values if any part is missing
    
      for (const part of orderedParts) {
        if (part.src) {
          const offscreenCanvas = await loadAndCacheImage(part);
          drawPart(part, offscreenCanvas);
        }
      }
    };


    drawParts();
  }, [memoizedParts]);

  return   <canvas
  ref={(node) => {
    canvasRef.current = node;
    ref.current = node;
  }}
  width={400} // 캔버스의 너비
  height={400} // 캔버스의 높이
  style={{
    width: '400px',
    height: '400px',
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'block',
  }}
/>
});

export default Canvas;