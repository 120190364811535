import React from 'react';

function PartSelector({ selectedCategory, selectedPart, onSelectPart, partCounts }) {
  const generatePartOptions = (category, count) => {
    // "empty" 옵션을 제외할 카테고리 목록
    const excludedEmptyCategories = ['face_face', 'hair_back', 'eye_iris'];
  
    // 카테고리 이름 간소화 함수
    const formatCategoryName = (name) =>
      name.replace(/^.*?_/, '').replace(/_/g, ' ');
  
    // "empty" 옵션 추가 (제외 카테고리에는 추가하지 않음)
    const options = !excludedEmptyCategories.includes(category)
      ? [
          {
            id: `/assets/${category}/empty.png`, // "빈" 상태를 나타내는 경로
            label: `X`, // 표시할 이름
            img: null, // 빈 파츠는 이미지를 사용하지 않음
          },
        ]
      : [];
  
    // 기존 파츠 추가
    const partOptions = Array.from({ length: count }, (_, i) => {
      const index = String(i + 1);
      const paddedIndex = index.padStart(Math.max(5, index.length), '0'); // 패딩
      return {
        id: `/assets/${category}/_${paddedIndex}.png`,
        img: `/assets/${category}/_${paddedIndex}.png`, // 이미지 경로
        label: `${formatCategoryName(category)} ${i + 1}`, // 표시할 이름
      };
    });
  
    return options.concat(partOptions); // "빈" 파츠와 기존 파츠를 결합
  };
  
  const partOptions = {
    bg_bg: generatePartOptions('bg_bg', partCounts.bg_bg),
    face_face: generatePartOptions('face_face', partCounts.face_face),
    face_ears: generatePartOptions('face_ears', partCounts.face_ears),
    face_blush: generatePartOptions('face_blush', partCounts.face_blush),
    face_mouth: generatePartOptions('face_mouth', partCounts.face_mouth),
    face_eyebrow: generatePartOptions('face_eyebrow', partCounts.face_eyebrow),
    face_deco: generatePartOptions('face_deco', partCounts.face_deco),
    eye_eyewhite: generatePartOptions('eye_eyewhite', partCounts.eye_eyewhite),
    eye_iris: generatePartOptions('eye_iris', partCounts.eye_iris),
    eye_highlight: generatePartOptions('eye_highlight', partCounts.eye_highlight),
    eye_eyelid: generatePartOptions('eye_eyelid', partCounts.eye_eyelid),
    eye_deco: generatePartOptions('eye_deco', partCounts.eye_deco),
    eye_deco2: generatePartOptions('eye_deco2', partCounts.eye_deco2),
    hair_back: generatePartOptions('hair_back', partCounts.hair_back),
    hair_backadd: generatePartOptions('hair_backadd', partCounts.hair_backadd),
    hair_sideR: generatePartOptions('hair_sideR', partCounts.hair_sideR), 
    hair_sideL: generatePartOptions('hair_sideL', partCounts.hair_sideL), 
    hair_tieR: generatePartOptions('hair_tieR', partCounts.hair_tieR), 
    hair_tieL: generatePartOptions('hair_tieL', partCounts.hair_tieL), 
    hair_front: generatePartOptions('hair_front', partCounts.hair_front),
    hair_add: generatePartOptions('hair_add', partCounts.hair_add),       
    hair_add2: generatePartOptions('hair_add2', partCounts.hair_add2),       
    hair_decof: generatePartOptions('hair_decof', partCounts.hair_decof), 
    hair_decof2: generatePartOptions('hair_decof2', partCounts.hair_decof2), 
    hair_decob: generatePartOptions('hair_decob', partCounts.hair_decob), 

  };
  

  return (
<div className="part-selector">
  <div className="parts">
    {partOptions[selectedCategory]?.map((part) => (
      <button
        key={part.id}
        className={`part-button ${selectedPart === part.id ? 'selected' : ''}`}
        onClick={() => onSelectPart(selectedCategory, part.id)}
      >
        {part.img ? (
          <img
            src={part.img}
            alt="Part Thumbnail"
            className="part-thumbnail"
          />
        ) : (
          <span className="empty-part-label">{part.label}</span> // "X" 표시
        )}
      </button>
    ))}
  </div>
</div>

  );
}

export default PartSelector;
