import React from 'react';

function ControlPanel({ onMove, onFlip, onRotate }) {
  return (
    <div className="control-panel">
      <div className="control-buttons">
        <div className="direction-buttons">
        <div className="horizontal-buttons">
          <button onClick={() => onMove('up')} className="direction-button up">↑</button>
            <button onClick={() => onMove('left')} className="direction-button left">←</button>
            <button onClick={() => onMove('right')} className="direction-button right">→</button>
          <button onClick={() => onMove('down')} className="direction-button down">↓</button>
          </div>

        </div>
      </div>
      <div className="control-buttons">
        <button onClick={onFlip} className="action-button">⇄ Flip</button>
        <button onClick={() => onRotate(15)} className="action-button">↻ Rotate Right</button>
        <button onClick={() => onRotate(-15)} className="action-button">↺ Rotate Left</button>
      </div>
    </div>
  );
}

export default ControlPanel;