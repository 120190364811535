export function adjustColors(imageData, ctx, targetColor, newColor) {
    const data = imageData.data;

    const hexToRgb = (hex) => {
        if (!hex) return null;
        let bigint = parseInt(hex.slice(1), 16);
        return {r: (bigint >> 16) & 255, g: (bigint >> 8) & 255, b: bigint & 255};
    };

    const rgbToHsl = (r, g, b) => {
        r /= 255; g /= 255; b /= 255;
        const max = Math.max(r, g, b), min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;
        if (max === min) {
            h = s = 0;
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }
        return [h, s, l];
    };

    const hslToRgb = (h, s, l) => {
        let r, g, b;
        if (s === 0) {
            r = g = b = l;
        } else {
            const hue2rgb = (p, q, t) => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1/6) return p + (q - p) * 6 * t;
                if (t < 1/2) return q;
                if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
                return p;
            };
            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;
            r = hue2rgb(p, q, h + 1/3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1/3);
        }
        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    };

    const lowerBound = hexToRgb('#56524d');
    const targetRgb = hexToRgb(targetColor);
    const newRgb = hexToRgb(newColor);

    if (!targetRgb || !newRgb) return;

    const targetHsl = rgbToHsl(targetRgb.r, targetRgb.g, targetRgb.b);
    const newHsl = rgbToHsl(newRgb.r, newRgb.g, newRgb.b);

    const isMainColor = (r, g, b) => {
        const diff = Math.abs(r - targetRgb.r) + Math.abs(g - targetRgb.g) + Math.abs(b - targetRgb.b);
        return diff < 60; // 임계값을 조정하여 메인 컬러 판단 기준을 변경할 수 있습니다
    };
    const getHueDiffSensitivity = (hue) => {
        const radian = (hue % 360) * Math.PI / 180;
        const baseSensitivity = Math.cos(radian);
        const scaledSensitivity = baseSensitivity * -0.05 + 0.0;
        
        const distanceFrom39 = Math.min(Math.abs(hue - 39), Math.abs(hue - 399));
        const dampingFactor = Math.max(0, 1 - Math.pow(Math.E, -distanceFrom39 / 10));
        
        return scaledSensitivity * dampingFactor;
    };

    const isFluorescentColor = (r, g, b) => {
        const [h, s, l] = rgbToHsl(r, g, b);
        return s > 0.5 && l > 0.7;
    };

    const ensureNotDarkerThan = (r, g, b, threshold) => {
        return [
            Math.max(r, threshold.r),
            Math.max(g, threshold.g),
            Math.max(b, threshold.b)
        ];
    };

    for (let i = 0; i < data.length; i += 4) {
        let r = data[i], g = data[i + 1], b = data[i + 2];

        // #56524d와 동일한 색상은 변경하지 않음
        if (r === lowerBound.r && g === lowerBound.g && b === lowerBound.b) {
            continue;
        }

        if (isMainColor(r, g, b)) {
            [r, g, b] = [newRgb.r, newRgb.g, newRgb.b];
        } else {
            let [h, s, l] = rgbToHsl(r, g, b);

            const hueDiff = (h - targetHsl[0] + 1) % 1;
            const sensitivity = getHueDiffSensitivity(h * 360);
            h = (newHsl[0] + hueDiff * sensitivity) % 1;

            s = Math.max(0, Math.min(1, s * (newHsl[1] / targetHsl[1])));
            l = Math.max(lowerBound.r / 255, Math.min(1, l * (newHsl[2] / targetHsl[2])));

            if (isFluorescentColor(r, g, b)) {
                s *= 0.8;
                l *= 0.9;
            }

            [r, g, b] = hslToRgb(h, s, l);
        }

        // 최종 RGB 값이 #56524d보다 어둡지 않도록 조정
        [r, g, b] = ensureNotDarkerThan(r, g, b, lowerBound);

        data[i] = r;
        data[i + 1] = g;
        data[i + 2] = b;
    }

    ctx.putImageData(imageData, 0, 0);
}